.top-footer {
  background: {
    image: url('../images/common/bg_fab1.jpg');
  }
  margin: ( 180 / 1500 ) * 100% auto 0;
  max-width: 1500px;
  padding: {
    top:  ( 143 / 1500 ) * 100%;
    bottom: 120px;
  }
  position: relative;

  &::after {
    background: {
      image: url('../images/common/footer_line.png');
      repeat: no-repeat;
      position: left bottom;
    }
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin: {
      left: ( 75 / 1500 ) * 100%;
      right: ( 45 / 1500 ) * 100%;
    }
    @media #{$small} {
      flex-direction: column;
      margin: {
        left: 20px;
        right: 20px;
      }
    }
  }

  &-info {
    flex-basis: 650px;
    flex-shrink: 1;
    margin: {
      right: ( 170 / 1500 ) * 100%;
    }
    @media #{$small} {
      flex-basis: auto;
      margin: {
        right: 0;
      }
    }

    &-catch {
      color: #222222;
      line-height: 1.2;
    }

    &-title {
      color: #212121;
      margin: {
        top: 40px;
      }

      &-en {
        font: {
          size: 2.3rem;
        }
        margin-right: 1em;
      }
    }

  }

  &-nav {
    margin: {
      top: 30px;
    }

    &-item {
      display: inline-block;
      margin: {
        right: ( 30 / 650 ) * 100%;
        bottom: ( 30 / 650 ) * 100%;
      }

      &:last-child {
        margin: {
          right: 0;
        }
      }

      a {
        &:link,
        &:hover,
        &:visited {
          color: #444444;
        }
      }
    }
  }

  &-access {
    color: #444444;
    margin-top: 35px;
  }

  &-station {
    margin-top: 30px;

    &-item {
      margin-top: 18px;

      &:first-child {
        margin-top: 0;
      }

      &-name {
        display: inline-block;
        width: 215px;
        @media #{$small} {
          width: 100%;
        }
      }

      &-time {
        display: inline-block;
      }
    }
  }

  &-media {
    margin-top: 85px;
  }

  &-sns {
    &-item {
      display: inline-block;
      margin: {
        right: 46px;
        bottom: 46px;
      }
      vertical-align: top;

      &:nth-child(1) {
        margin: {
          top: 7px;
        }
      }

      &:nth-child(2) {
        margin: {
          top: 7px;
        }
      }

      &:last-child {
        margin: {
          right: 0;
        }
      }
    }
  }

  &-aside {
    flex-basis: 568px;
    flex-shrink: 1;
    @media #{$small} {
      flex-basis: auto;
    }
  }

  &-map {
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  &-copyright {
    font: {
      size: 1.9rem;
      // weight: 700;
    }
    margin-top: 60px;
    text-align: center;
  }

  &-image {
    position: absolute;
    top: 0;
    left: 50%;
    width: ( 510 / 1500 ) * 100%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
    }
  }

  &-googlemap {
    width: 100%;
    padding: {
      top: 100%;
    }
    position: relative;

    iframe {
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}


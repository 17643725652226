.visiting {
  background: {
    image: url('../images/common/bg_top_1.jpg');
  }
  margin: 0 auto;
  padding: {
    bottom: 210px;
  }
  max-width: 1500px;

  &-visual {
    position: relative;

    &-image {
      img {
        width: 100%;
      }
    }

    &-catch {
      background: {
        color: rgba(255, 255, 255, 0.43);
      }
      padding: {
        top: 65px;
        bottom: 34px;
      }
      position: absolute;
      top: 50%;
      left: 0;
      text-align: center;
      transform: translate(0 -55%);
      width: 100%;
      @media #{$small} {
        padding: 16px 0 8px;
      }

      &-title {
        display: inline-block;
        font: {
          size: 3.3rem;
        }
        line-height: ( 39.6 / 33 );
        position: relative;
        @media #{$small} {
          font: {
            size: 1.6rem;
          }
        }

        &-jp {
          font: {
            size: 1.8rem;
            weight: 700;
          }
          letter-spacing: 0.1em;
          position: absolute;
          top: -1em;
          right: 1.5em;
          @media #{$small} {
            font: {
              size: 1.0rem;
            }
          }
        }
      }

      &-sub {
        font: {
          size: 2.5rem;
        }
        line-height: ( 47.25 / 25 );
        margin: {
          top: 12px;
        }
        @media #{$small} {
          font: {
            size: 1.2rem;
          }
          margin: {
            top: 6px;
          }
        }
      }
    }
  }

  &-about {
    margin: 0 auto;
    padding: {
      top: 75px;
    }
    text-align: center;
    max-width: ( 1200 / 1500 ) * 100%;

    &-text {
      font: {
        size: 2.0rem;
        weight: 700;
      }
      line-height: ( 37.8 / 20 ) * 100%;
      margin: {
        bottom: 48px;
      }
      @media #{$small} {
        font: {
          size: 1.2rem;
        }
        text-align: left;
      }
    }

    &-message {
      font: {
        size: 3.6rem;
      }
      line-height: ( 90 / 36 );
      @media #{$small} {
        font: {
          size: 1.6rem;
        }
      }
    }
  }

  &-menu {
    margin: {
      top: 70px;
    }

    &-title {
      text-align: right;

      img {
        width: ( 1346 / 1500 ) * 100%;
      }
    }

    &-list {
      display: flex;
      justify-content: center;
      margin: {
        top: 32px;
        left: auto;
        right: auto;
      }
      width: ( 1200 / 1500 ) * 100%;
      @media (max-width: 1053px) {
        flex-direction: column;
        margin: {
          top: 30px;
        }
      }

      img {
        max-width: 100%;
      }

      &-item {
        margin: {
          left: ( 22 / 1200 ) * 100%;
        }
        width: 48%;
        @media (max-width: 1053px) {
          margin: {
            top: 30px;
            left: 0;
          }
          width: 100%;
        }

        &:nth-child(1) {
          margin: {
            left: 0;
          }
          @media #{$small} {
            margin: {
              top: 0;
            }
          }
        }

        &-title {
          background-image: url('../images/visiting/bg_headline.png');
          border-left: 5px solid #732a2d;
          color: #732a2d;
          font: {
            size: 2.8rem;
            weight: 700;
          }
          letter-spacing: -0.1em;
          margin: {
            bottom: 10px;
          }
          padding: 8px 0 7px 15px;

          @media #{$small} {
            font: {
              size: 2.0rem;
            }
          }
        }

        &-note {
          font: {
            size: 1.4rem;
          }
          line-height: 1.4;
          margin: {
            top: 10px;
          }
        }

        &-list {
          display: table;
          width: 100%;

          @media (max-width: 420px) {
            display: block;
          }
        }

        &-name {
          display: table-cell;
          font: {
            weight: bold;
          }
          line-height: 1.4;
          padding: {
            top: 8px;
            bottom: 8px;
          }

          @media #{$small} {
            font: {
              size: 1.4rem;
            }
          }

          @media (max-width: 420px) {
            display: block;
          }
        }

        &-cost {
          color: $red;
          display: table-cell;
          font: {
            size: 2.4rem;
            weight: 700;
          }
          text-align: right;

          @media #{$small} {
            font: {
              size: 1.8rem;
            }
          }

          @media (max-width: 420px) {
            display: block;
          }

          &-note {
            font: {
              size: 1.3rem;
            }
          }
        }

        &-image {
          margin: {
            top: 10px;
            bottom: 10px;
          }
        }
      }
    }

    &-note {
      margin: 60px auto 0;
      width: ( 1180 / 1500 ) * 100%;
      @media #{$small} {
        margin: {
          top: 30px;
        }
      }
    }

    &-text {
      font: {
        size: 2.0rem;
      }
      line-height: ( 37.8 / 20 );
      @media #{$small} {
        font: {
          size: 1.4rem;
        }
      }
    }
  }

  &-flow {
    margin: {
      top: 92px;
    }

    &-title {
      text-align: right;

      img {
        width: ( 1346 / 1500 ) * 100%;
      }
    }

    &-photo {
      display: flex;
      justify-content: space-between;
      margin: {
        top: 25px;
        left: auto;
        right: auto;
      }
      max-width: ( 1200 / 1500 ) * 100%;

      &-item {
        flex-basis: 370px;
        flex-shrink: 1;
        margin: {
          left: ( 40 / 1200 ) * 100%;
        }

        &:nth-child(1) {
          margin: {
            left: 0;
          }
        }

        img {
          width: 100%;
        }
      }
    }

    &-step {
      display: table;
      margin: 22px auto 0;
      width: ( 1200 / 1500 ) * 100%;
      @media #{$small} {
        display: block;
      }

      &-item {
        display: table-cell;
        @media #{$small} {
          display: block;
        }

        &:nth-child(1) {
          width: 544px * 0.4;
        }

        &:nth-child(2) {
          margin: {
            top: 10px;
          }
          width: 658px * 0.4;
        }

        img {
          max-width: 100%;
        }
      }
    }

    &-concept {
      display: table;
      margin: {
        top: 63px;
      }
      width: 100%;
      overflow: hidden;
      @media #{$small} {
        display: block;
        margin: {
          top: 30px;
        }
      }

      &-item {
        display: table-cell;
        @media #{$small} {
          display: block;
        }

        &:nth-child(1) {
          @media #{$small} {
            margin: {
              right: ( 150 / 1500 ) * 100%;
              left: -( 315 / 1500 ) * 100%;
            }
          }
        }

        &:nth-child(2) {
          @media #{$small} {
            margin: {
              right: ( 75 / 1500 ) * 100%;
              left: ( 75 / 1500 ) * 100%;
            }
          }
        }

        &:nth-child(3) {
          @media #{$small} {
            margin: {
              left: ( 180 / 1500 ) * 100%;
              right: -( 255 / 1500 ) * 100%;
            }
          }
        }
      }

      img {
        width: 100%;
      }
    }
  }

  &-faq {
    margin: {
      top: 90px;
    }

    &-title {
      text-align: right;

      img {
        width: ( 1346 / 1500 ) * 100%;
      }
    }

    &-list {
      margin: 60px auto 0;
      width: ( 1180 / 1500 ) * 100%;
      @media #{$small} {
        margin: {
          top: 30px;
        }
      }
    }

    &-item {
      margin: {
        top: 38px;
      }
      @media #{$small} {
        font: {
          size: 1.4rem;
        }
        margin: {
          top: 30px;
        }
      }

      &-title {
        font: {
          size: 2.0rem;
          weight: 700;
        }
        margin: {
          bottom: 10px;
        }
        @media #{$small} {
          font: {
            size: 1.4rem;
          }
        }
      }

      &-text {
        font: {
          size: 1.8rem;
        }
        line-height: 1.4;
        @media #{$small} {
          font: {
            size: 1.4rem;
          }
        }
      }
    }
  }
}


.wig {
  background: {
    image: url('../images/common/bg_top_1.jpg');
  }
  margin: 0 auto;
  padding: {
    bottom: 210px;
  }
  max-width: 1500px;

  &-visual {
    position: relative;

    &-image {
      img {
        width: 100%;
      }
    }

    &-catch {
      background: {
        color: rgba(255, 255, 255, 0.43);
      }
      padding: {
        top: 65px;
        bottom: 34px;
      }
      position: absolute;
      top: 50%;
      left: 0;
      text-align: center;
      transform: translate(0 -55%);
      width: 100%;
      @media #{$small} {
        padding: 10px 0 8px;
      }

      &-title {
        font: {
          size: 3.3rem;
        }
        line-height: ( 39.6 / 33 );
        @media #{$small} {
          font: {
            size: 1.6rem;
          }
        }
      }

      &-sub {
        font: {
          size: 2.5rem;
        }
        line-height: ( 47.25 / 25 );
        margin: {
          top: 12px;
        }
        @media #{$small} {
          font: {
            size: 1.2rem;
          }
          margin: {
            top: 6px;
          }
        }
      }
    }
  }

  &-point {
    margin: 0 auto;
    padding: {
      top: 70px;
    }
    text-align: center;
    width: ( 1200 / 1500 ) * 100%;

    img {
      max-width: 100%;
    }
  }

  &-fiber {
    margin: {
      top: 94px;
    }

    &-title {
      text-align: right;

      img {
        width: ( 1346 / 1500 ) * 100%;
      }
    }

    &-images {
      margin: {
        top: 54px;
        left: auto;
        right: auto;
      }
      display: flex;
      justify-content: center;
      text-align: center;
      width: ( 1200 / 1500 ) * 100%;
      @media #{$small} {
        margin: {
          top: 30px;
        }
      }

      img {
        max-width: 100%;
      }
    }

    &-main {
      flex-basis: 350px;
      margin: {
        top: 18px;
        right: ( 70 / 1500 ) * 100%;
      }
      @media #{$small} {
        margin: {
          top: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    &-feature {
      flex-basis: 394px;
    }

    &-photos {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        width: ( 1210 / 1500 ) * 100%;
      }

      &-title {
        margin-top: 90px;
        width: 50%;
        @media #{$small} {
          width: 100%;
        }

        &:nth-child(1) {
          img {
            width: ( 275 / 750 ) * 100%;
            @media #{$small} {
              width: ( 351px / 2 );
            }
          }
          @media #{$small} {
            order: 1;
          }
        }

        &:nth-child(2) {
          img {
            @media #{$small} {
              width: ( 337px / 2 );
            }
          }
          @media #{$small} {
            order: 3;
          }
        }
      }

      &-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: {
          left: auto;
          right: auto;
        }
        width: ( 1210 / 1500 ) * 100%;
        @media #{$small} {
          flex-direction: column;
        }
      }

      &-before {
        margin: {
          top: 40px;
        }
        width: ( 248 / 1210 ) * 100%;
        @media #{$small} {
          display: table;
          order: 2;
          margin: {
            top: 30px;
          }
          width: 100%;
        }

        &-item {
          margin: {
            bottom: 40px;
          }
          @media #{$small} {
            display: table-cell;
            width: 50%;
          }

          &:last-child {
            margin: {
              bottom: 0;
            }
          }

          img {
            max-width: 100%;
          }
        }
      }

      &-after {
        border: {
          left: 6px dotted #663300;
        }
        box-sizing: border-box;
        margin: {
          top: 40px;
        }
        padding: {
          left: ( 55 / 1500 ) * 100%;
        }
        width: ( 850 / 1210 ) * 100%;
        @media #{$small} {
          border: {
            left: none;
          }
          margin: {
            top: 30px;
          }
          padding: {
            left: 0;
          }
          order: 4;
          width: 100%;
        }

        &-item {
          margin: {
            bottom: 50px;
          }

          &:last-child {
            margin: {
              bottom: 0;
            }
          }

          img {
            max-width: 100%;
          }
        }
      }
    }

    &-about {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: {
        top: 75px;
        left: auto;
        right: auto;
      }
      max-width: ( 1200 / 1500 ) * 100%;
      @media #{$small} {
        flex-direction: column;
      }

      &-image {
        width: ( 490 / 1200 ) * 100%;
        @media #{$small} {
          width: 100%;
        }

        img {
          max-width: 100%;
        }
      }

      &-text {
        font: {
          size: 2.9rem;
          weight: 700;
        }
        line-height: ( 60.61 / 29 );
        margin: {
          left: ( 140 / 1500 ) * 100%;
        }
        @media #{$small} {
          font: {
            size: 1.5rem;
          }
          margin: {
            top: 30px;
            left: 0;
          }
          width: 100%;
        }
      }
    }

    &-video {
      margin: {
        top: 105px;
        left: auto;
        right: auto;
      }
      width: ( 850 / 1500 ) * 100%;
      @media #{$small} {
        margin: {
          top: 60px;
        }
        width: ( 1200 / 1500 ) * 100%;
      }

      &-youtube {
        padding: {
          top: 56.25%;
        }
        position: relative;
        width: 100%;

        iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  &-price {
    margin: {
      top: 123px;
    }

    &-title {
      text-align: right;

      img {
        width: ( 1346 / 1500 ) * 100%;
      }
    }

    &-image {
      margin: {
        top: 58px;
        left: auto;
        right: auto;
      }
      text-align: center;
      width: ( 1200 / 1500 ) * 100%;
      @media #{$small} {
        margin: {
          top: 30px;
        }
      }

      img {
        max-width: 100%;
      }
    }

    &-summary {
      color: #732a2d;
      font: {
        size: 3.8rem;
        weight: 700;
      }
      letter-spacing: -0.1em;
      margin: {
        top: 58px;
      }
      text-align: center;

      @media #{$small} {
        font: {
          size: 2.0rem;
        }
      }

      @media (max-width: 420px) {
        font: {
          size: 1.6rem;
        }
      }
    }

    &-list {
      box-sizing: border-box;
      display: table;
      margin:{
        top: 30px;
        bottom: 30px;
      }
      padding: 0 ( 152 / 1500 ) * 100%;
      text-align: center;
      width: 100%;
    }

    &-name {
      display: table-cell;
      font: {
        size: 3.8rem;
        weight: 700;
      }

      @media #{$small} {
        font: {
          size: 2.0rem;
        }
      }

      @media (max-width: 420px) {
        font: {
          size: 1.6rem;
        }
      }

      // &::after {
      //   background-image: url('../images/wig/bg_dotted.png');
      //   content: '';
      //   display: inline-block;
      //   margin: 0.5em 10px 0;
      //   width: 144px;
      //   height: 4px;
      //   vertical-align: top;
      // }
    }

    &-cost {
      color: #732a2d;
      display: table-cell;
      font: {
        size: 3.8rem;
        weight: 700;
      }

      @media #{$small} {
        font: {
          size: 2.0rem;
        }
      }

      @media (max-width: 420px) {
        font: {
          size: 1.6rem;
        }
      }
    }

    &-note {
      font: {
        size: 2.0rem;
      }

      @media #{$small} {
        font: {
          size: 1.2rem;
        }
      }
    }
  }
}

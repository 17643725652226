.top-header {
  margin: 0 auto;
  max-width: 1500px;
  position: relative;

  &::before {
    background-image: url('../images/common/line_01.png');
    background-repeat: repeat-x;
    content: '';
    height: 27px;
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
    top: 0;
  }

  &::after {
    background-image: url('../images/common/line_02.png');
    background-repeat: repeat-x;
    content: '';
    height: 16px;
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
    bottom: -5px;
  }

  &-wrapper {
    display: flex;
    padding: 3px 0;
    @media #{$small} {
      flex-direction: column;
      overflow: hidden;
    }
  }

  &-body {
    flex-basis: 492px;
    flex-shrink: 1;
    position: relative;
    z-index: 2;
    @media #{$small} {
      flex-basis: auto;
    }
  }

  &-logo {
    width: ( 527 / 492 ) * 100%;
    position: absolute;
    z-index: 2;

    img {
      width: 100%;
    }
  }

  &-info {
    position: relative;
    text-align: center;
    z-index: 3;

    &-image {
      display: inline-block;
      margin-top: ( 435 / 492 ) * 100%;
      width: ( 393 / 492 ) * 100%;

      img {
        max-width: 100%;
      }
    }
  }

  &-aside {
    flex-basis: 1008px;
    flex-shrink: 1;
    width: ( 1008 / 1500 ) * 100%;
    position: relative;
    z-index: 1;
    @media #{$small} {
      flex-basis: auto;
      width: 100%;
    }
  }
}

.slide {
  &-item {
    img {
      width: 100%;
    }
  }
}

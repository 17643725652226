.salon {
  background: {
    // image: url('../images/common/bg_top_1.jpg');
  }
  margin: 0 auto;
  padding: {
    bottom: 210px;
  }
  max-width: 1500px;

  &-visual {
    position: relative;

    &-image {
      img {
        width: 100%;
      }
    }

    &-catch {
      background: {
        color: rgba(255, 255, 255, 0.43);
      }
      padding: {
        top: 65px;
        bottom: 34px;
      }
      position: absolute;
      top: 50%;
      left: 0;
      text-align: center;
      transform: translate(0 -55%);
      width: 100%;
      @media #{$small} {
        padding: 10px 0 8px;
      }

      &-title {
        font: {
          size: 3.3rem;
        }
        line-height: ( 39.6 / 33 );
        @media #{$small} {
          font: {
            size: 1.6rem;
          }
        }
      }

      &-sub {
        font: {
          size: 2.5rem;
        }
        line-height: ( 47.25 / 25 );
        margin: {
          top: 12px;
        }
        @media #{$small} {
          font: {
            size: 1.2rem;
          }
          margin: {
            top: 6px;
          }
        }
      }
    }
  }

  &-about {
    margin: 0 auto;
    padding: {
      top: 60px;
    }
    max-width: ( 1200 / 1500 ) * 100%;

    &-lead {
      font: {
        size: 2.0rem;
      }
      line-height: ( 37.8 / 20 );
      text-align: center;
      @media #{$small} {
        font: {
          size: 1.2rem;
        }
        text-align: left;
      }
    }

    &-concept {
      display: flex;
      justify-content: center;
      margin: {
        top: 110px;
      }
      @media #{$small} {
        flex-direction: column;
        margin: {
          top: 60px;
        }
      }

      &-text {
        font: {
          size: 2.0rem;
        }
        line-height: ( 37.8 / 20 );
        margin: {
          top: 60px;
        }
        @media #{$small} {
          font-size: 1.6rem;
          margin: {
            top: 30px;
          }
        }
      }
    }

    &-feel {
      flex-basis: 440px;
      margin: {
        right: ( 180 / 1500 ) * 100%;
      }
      @media #{$small} {
        flex-basis: auto;
        margin: {
          right: 0;
          bottom: 30px;
        }
      }

      &-title {
        width: 50%;

        img {
          width: 100%;
        }
      }
    }

    &-style {
      flex-basis: 510px;
      @media #{$small} {
        flex-basis: auto;
      }

      .salon-about-concept-title {
        margin: {
          top: -5px;
        }
      }

      &-title {
        width: 50%;

        img {
          width: 100%;
        }
      }
    }

    &-message {
      margin: {
        top: 105px;
      }
      text-align: center;
      @media #{$small} {
        margin: {
          top: 50px;
        }
      }

      &-jp {
        font: {
          size: 2.0rem;
        }
        @media #{$small} {
          font: {
            size: 1.6rem;
          }
          line-height: 1.6;
        }

        &-span {
          @media #{$small} {
            display: block;
          }
        }
      }

      &-en {
        margin: {
          top: 45px;
          left: auto;
          right: auto;
        }
        width: ( 367 / 1500 ) * 100%;
        @media #{$small} {
          width: 80%;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  &-price {
    margin: {
      top: 70px;
    }

    &-title {
      text-align: right;

      img {
        width: ( 1345 / 1500 ) * 100%;
      }
    }

    &-body {
      display: table;
      margin: {
        top: 44px;
        left: auto;
        right: auto;
      }
      width: ( 1200 / 1500 ) * 100%;
      @media (max-width: 1400px) {
        display: block;
        margin: {
          top: 0;
        }
      }
    }

    &-block {
      display: table-cell;
      width: 48%;
      @media (max-width: 1400px) {
        display: block;
        margin: {
          top: 44px;
        }
        width: 100%;
      }

      &:nth-child(2) {
        width: 4%;
      }

      &-name {
        border-bottom: 2px solid #a79a8d;
        font: {
          size: 2.4rem;
          weight: 700;
        }
        padding-bottom: 5px;
        @media (max-width: 700px) {
          font: {
            size: 1.8rem;
          }
          text-align: center;
        }

        &-note {
          @media (max-width: 700px) {
            display: block;
            font: {
              size: 1.4rem;
            }
          }
        }
      }
    }

    &-contents {
      display: table;
      margin: {
        top: 22px;
      }
      width: 100%;
    }

    &-list {
      display: table-cell;
      vertical-align: top;
      width: 48%;
      @media (max-width: 700px) {
        display: block;
        width: 100%;
      }

      &:nth-child(2) {
        border-left: 1px dotted #a79a8d;
        padding: {
          left: 4%;
        }
        @media (max-width: 700px) {
          border-left: none;
          padding: {
            left: 0;
          }
        }
      }

      &-title {
        font: {
          size: 2.0rem;
          weight: 700;
        }
        margin: {
          bottom: 8px;
        }
        text-align: center;
        @media (max-width: 700px) {
          font: {
            size: 1.6rem;
          }
        }
      }

      &-title2 {
        font: {
          size: 1.4rem;
        }
        margin: {
          bottom: 8px;
        }
        text-align: center;
      }
    }

    &-list2 {
    }

    &-list3 {
    }

    &-container {
      &:nth-child(2) {
        @media (max-width: 700px) {
          border-bottom: 1px dotted #a79a8d;
          margin: {
            top: 40px;
          }
        }
      }
    }

    &-container2 {
      padding: {
        top: 2em;
      }
    }

    &-item {
      white-space: nowrap;
      margin: {
        bottom: 10px;
      }
      @media (max-width: 700px) {
        display: table;
        width: 100%;
      }
    }

    &-label {
      display: inline-block;
      width: 150px;
      @media (max-width: 700px) {
        display: table-cell;
        vertical-align: middle;
        width: 70%;
      }

      &-en {
        font: {
          size: 2.4rem;
        }
      }

      &-jp {
        font: {
          size: 1.3rem;
        }
        letter-spacing: -0.1em;
      }

      &-number {
        font: {
          size: 1.6rem;
        }
      }

      &-note {
        display: block;
        font: {
          size: 1.2rem;
        }
        margin: {
          left: -0.5em;
        }
      }
    }

    &-data {
      font: {
        size: 2.4rem;
        weight: 700;
      }
      @media (max-width: 700px) {
        display: table-cell;
        vertical-align: middle;
        width: 30%;
      }
    }
  }

  &-map {
    margin: {
      top: 64px;
    }

    &-title {
      text-align: right;

      img {
        width: ( 1346 / 1500 ) * 100%;
      }
    }

    &-image {
      margin: {
        top: 50px;
        left: 12%;
      }
      max-width: ( 1200 / 1500 ) * 100%;
      @media #{$small} {
        margin: {
          top: 30px;
        }
      }

      img {
        max-width: 100%;
      }
    }

    &-googlemap {
      margin: 50px auto 0;
      padding: {
        top: 50%;
      }
      width: 80%;
      position: relative;

      iframe {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &-booking {
    margin: {
      top: 88px;
    }

    &-title {
      text-align: right;

      img {
        width: ( 1346 / 1500 ) * 100%;
      }
    }

    &-body {
      display: flex;
      justify-content: space-between;
      margin: 45px ( 150 / 1500 ) * 100% 0;
      @media #{$small} {
        flex-direction: column;
        margin: {
          top: 30px;
        }
      }
    }

    &-off {
      @media #{$small} {
        margin: 0 auto;
        width: 50%;
      }

      img {
        max-width: 100%;
      }
    }

    &-contact {
      flex-basis: 780px;
      margin: {
        top: 60px;
      }
      @media #{$small} {
        flex-basis: auto;
        margin: {
          top: 30px;
        }
      }

      &-image {
        margin: {
          left: 98px;
        }
        @media #{$small} {
          margin: {
            left: 0;
          }
        }

        img {
          max-width: 100%;
        }
      }

      &-notice {
        font: {
          size: 2.1rem;
        }
        line-height: ( 36 / 21 );
        margin: {
          top: 45px;
          left: 98px;
        }
        @media #{$small} {
          font: {
            size: 1.4rem;
          }
          margin: {
            left: 0;
          }
        }
      }
    }
  }

  .sub-page-title {
    font: {
      family: sans-serif;
    }
  }
}

.header {
  background: {
    image: url('../images/common/bg_top_1.jpg');
  }
  margin: 0 auto;
  max-width: 1500px;
  position: relative;
  @media #{$small} {
    padding: {
      bottom: 30px;
    }
  }

  &::before {
    background-image: url('../images/common/line_01.png');
    background-repeat: repeat-x;
    content: '';
    height: 27px;
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
    top: 0;
  }

  &-wrapper {
    &::before {
      background: {
        image: url('../images/common/head_bg01.png');
        repeat: no-repeat;
        size: 100% auto;
      }
      content: '';
      position: absolute;
      left: 0;
      top: 27px;
      width: ( 176 / 1500 ) * 100%;
      height: 100%;
    }

    &::after {
      background: {
        image: url('../images/common/head_bg02.png');
        repeat: no-repeat;
        size: 100% auto;
      }
      content: '';
      position: absolute;
      right: 0;
      top: 27px;
      width: ( 112 / 1500 ) * 100%;
      height: 100%;
    }
  }

  &-logo {
    margin: 0 auto;
    padding: {
      top: 45px;
    }
    width: ( 523 / 1500 ) / 2 * 100%;
    @media #{$small} {
      width: ( 523 / 750 ) * 100%;
    }

    img {
      width: 100%;
    }
  }

  &-contact {
    position: absolute;
    top: 70px;
    right: 10.5%;
    width: ( 301 / 1500 ) * 100%;
    @media #{$small} {
      margin: 30px auto 0;
      position: relative;
      top: auto;
      right: auto;
      width: ( 500 / 750 ) * 100%;
    }

    &-image {
      img {
        width: 100%;
      }
    }
  }
}

.gnav {
  &-list {
    margin: {
      top: 30px;
    }
    text-align: center;
    @media #{$small} {
      display: table;
      table-layout: fixed;
      width: 100%;
    }
  }

  &-item {
    background: {
      repeat: no-repeat;
    }
    display: inline-block;
    font: {
      size: 2.4rem;
      weight: 700;
    }
    letter-spacing: 0.1em;
    margin: 0 35px 30px;
    padding: {
      top: 5px;
      bottom: 5px;
      left: 50px;
    }
    @media #{$small} {
      background: {
        position: center;
      }
      display: table-cell;
      font: {
        size: 1.2rem;
      }
      padding: 0;
    }

    &:nth-child(1) {
      background: {
        image: url('../images/top/head_green.png');
      }
    }

    &:nth-child(2) {
      background: {
        image: url('../images/top/head_red.png');
      }
    }

    &:nth-child(3) {
      background: {
        image: url('../images/top/head_blue.png');
      }
    }

    &-link {
      display: block;
      @media #{$small} {
        padding: 10px 0;
      }

      &:link,
      &:hover,
      &:visited {
        color: #59432c;
        text-decoration: none;
      }

      &-span {
        @media #{$small} {
          display: block;
        }
      }
    }
  }
}

html {
  font-size: 62.5%;
}

body {
  color: #59432c;
  font-family: "Yu Mincho", "YuMincho", serif;
  font-size: 1.6rem;
  -webkit-text-size-adjust: 100%;

  // background-image: url('../images/salon/dummy.png');
  background-position: center -14px;
  background-repeat: no-repeat;
}

img {
  vertical-align: bottom;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sub-page {
  &-title {
    background: {
      image: url('../images/common/bg_title.png');
      repeat: no-repeat;
      size: 100% auto;
      position: bottom right;
    }
    color: #732a2d;
    font: {
      size: 3.8rem;
      weight: 700;
    }
    margin: {
      left: ( 152 / 1500 ) * 100%;
    }
    padding: {
      top: 50px;
      bottom: ( 35 / 1500 ) * 100%;
    }
    @media #{$small} {
      font: {
        size: 2.0rem;
      }
    }

    @media (max-width: 420px) {
      font: {
        size: 1.6rem;
      }
    }

    &-span {
      font: {
        size: 1.8rem;
      }
      @media #{$small} {
        font: {
          size: 1.0rem;
        }
      }
    }
  }
}

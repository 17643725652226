.top-main {
  background: {
    image: url('../images/top/bg_tree.png');
    repeat: no-repeat;
    position: top right;
    size: ( ( 577 / 1500 ) * 100% ) auto;
  }
  margin: 0 auto;
  max-width: 1500px;
  padding-top: ( 60 / 1500 ) * 100%;

  &-title {
    text-align: center;

    img {
      width: ( 236 / 1500 ) * 100%;
      @media #{$small} {
        width: ( 256 / 768 ) * 100%;
      }
    }
  }

  &-contents {
    display: flex;
    justify-content: center;
    margin: 5.4% auto 0;
    @media #{$small} {
      flex-direction: column;
    }
  }

  &-item {
    flex-basis: 380px;
    flex-shrink: 1;
    margin: 0 ( 55 / 1500 ) * 100%;
    padding: {
      bottom: ( 86 / 1500 ) * 100%;
    }
    position: relative;
    @media #{$small} {
      flex-basis: auto;
      margin: {
        bottom: 60px;
      }
      padding: {
        bottom: ( 86 / 768 ) * 100%;
      }
    }

    &-title {
      background: {
        repeat: no-repeat;
      }
      font: {
        size: 2.4rem;
        weight: 700;
      }
      padding: {
        top: 6px;
        bottom: 6px;
        left: 45px;
      }
      @media #{$small} {
        font: {
          size: 2.0rem;
        }
        padding: {
          bottom: 10px;
        }
      }
    }

    &:nth-child(1) {
      .top-main-item-title {
        background: {
          image: url('../images/top/head_green.png');
        }
      }
    }

    &:nth-child(2) {
      .top-main-item-title {
        background: {
          image: url('../images/top/head_red.png');
        }
      }
    }

    &:nth-child(3) {
      .top-main-item-title {
        background: {
          image: url('../images/top/head_blue.png');
        }
      }
    }

    &-image {
      margin-top: 23px;
      @media #{$small} {
        margin-top: 10px;
      }

      img {
        width: 100%;
      }
    }

    &-text {
      font: {
        size: 1.9rem;
        weight: 700;
      }
      letter-spacing: 0.03em;
      line-height: 2;
      margin: {
        top: 15px;
        bottom: 30px;
      }
      @media #{$small} {
        font: {
          size: 1.6rem;
        }
      }
    }

    &-button {
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;

      img {
        display: inline-block;
        width: 100%;
        max-width: 376px;
        @media #{$small} {
          width: ( 376 / 768 ) * 100%;
          min-width: 240px;
        }
      }
    }
  }
}

.footer {
  background: {
    image: url('../images/common/bg_fab1.jpg');
  }
  margin: {
    left: auto;
    right: auto;
  }
  max-width: 1500px;
  position: relative;

  &::after {
    background: {
      image: url('../images/common/footer_line.png');
      repeat: no-repeat;
      position: left bottom;
    }
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
  }

  &-wrapper {
    margin: 0 auto;
    padding: {
      top: 260px;
      bottom: 160px;
    }
    text-align: center;
    @media #{$small} {
      padding: {
        top: 60px;
      }
      text-align: left;
    }
  }

  &-media {
    @media #{$small} {
      margin: 0 5%;
    }
  }

  &-sns {
    &-item {
      display: inline-block;
      margin: {
        right: 46px;
        bottom: 46px;
      }
      vertical-align: top;

      &:nth-child(1) {
        margin: {
          top: 7px;
        }
      }

      &:nth-child(2) {
        margin: {
          top: 7px;
        }
      }

      &:last-child {
        margin: {
          right: 0;
        }
      }
    }
  }

  &-copyright {
    font: {
      size: 1.9rem;
      // weight: 700;
    }
    margin-top: 18px;
    text-align: center;
  }

  &-image {
    position: absolute;
    top: 0;
    left: 50%;
    width: ( 510 / 1500 ) * 100%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
    }
  }
}
